import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
    name: "sidebar",
    initialState: {
        sidebar: window.location.pathname.split('/')[1] || 'postupleniye',
    },
    reducers: {
        changeSidebar: (state, action) => {
            state.sidebar = action.payload
        }
    }
})

export const { changeSidebar } = sidebarSlice.actions
export default sidebarSlice.reducer
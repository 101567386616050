import React, { useState } from 'react'
import { dateFormat } from '../../utils/dateFormat'
import { MdCheck, MdClose } from 'react-icons/md'
import RespondApplication from '../../modals/RespondApplication'
import ApplicationService from '../../services/applications/applications.service'
import { toast } from 'react-toastify'

const Application = (props) => {

    const { application, getAllApplications } = props

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [respondText, setRespondText] = useState('')
    const [respondId, setRespondId] = useState('')
    const [appStatus, setAppStatus] = useState('')

    const getApplicationDetails = async (id, uuid, status) => {
        setIsModalOpen(true)
        setRespondId(id)
        setAppStatus(status)

        try {
            const response = await ApplicationService.GetApplicationDetails(uuid)
            setRespondText(response.data.answers)
        } catch (err) {
            console.log(err)
        }
    }

    const respondApplicationById = async (e) => {
        e.preventDefault()

        const data = {
            application_id: respondId,
            answer_text: respondText
        }

        try {
            appStatus === 'pending' ? await ApplicationService.RespondApplication(data) : await ApplicationService.UpdateApplicationAnswer(data)
            setRespondText('')

            toast.success(appStatus === 'pending' ? "Javob berildi" : "Javob o'zgartirildi", {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            setIsModalOpen(false)
            getAllApplications()
        } catch (err) {
            toast.error(err.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }
    }

    return (
        <>
            {/* MODAL */}
            <RespondApplication
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                respondApplicationById={respondApplicationById}
                respondText={respondText}
                setRespondText={setRespondText}
            />


            <tr>
                <td>{application?.id}</td>
                <td>{application?.fish}</td>
                <td>
                    <a href={`tel:${application?.phone_number}`}>
                        {application?.phone_number}
                    </a>
                </td>
                <td>
                    {
                        application?.email ? (
                            <a href={`mailto:${application?.email}`}>
                                {application?.email}
                            </a>
                        ) : '-'
                    }
                </td>
                <td>
                    <span className='descr'>{application?.desc}</span>
                </td>
                <td>{dateFormat(application?.created_at)}</td>
                <td>
                    {
                        application?.status === 'pending' ? (
                            <span style={{ color: 'red' }}><MdClose /></span>
                        ) : (
                            <span style={{ color: 'green' }}><MdCheck /></span>
                        )
                    }
                </td>
                <td>{application?.uuid}</td>
                <td>
                    <button className='btn btn-warning' onClick={() => getApplicationDetails(application?.id, application?.uuid, application?.status)}>Javob berish</button>
                </td>
            </tr>
        </>
    )
}

export default Application
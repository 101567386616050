// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-component {
    position: relative;
}

.layout-component .sidebar-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 272px;
    height: 100vh;
    overflow-y: scroll;
    background-color: #ffffff;
}

.layout-component .sidebar-wrap::-webkit-scrollbar {
    width: 0;
}

.layout-component .navbar-wrap {
    position: absolute;
    top: 0;
    right: 24px;
    width: calc(100% - 320px);
    height: 96px;
    border-bottom: 1px solid #D9D9D9;
}

.layout-component .pages-wrap {
    position: absolute;
    top: 120px;
    right: 24px;
    width: calc(100% - 320px);
    height: calc(100vh - 144px);
    overflow-y: auto;
    padding: 0 20px;
    padding-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/layout.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,yBAAyB;IACzB,2BAA2B;IAC3B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".layout-component {\n    position: relative;\n}\n\n.layout-component .sidebar-wrap {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 272px;\n    height: 100vh;\n    overflow-y: scroll;\n    background-color: #ffffff;\n}\n\n.layout-component .sidebar-wrap::-webkit-scrollbar {\n    width: 0;\n}\n\n.layout-component .navbar-wrap {\n    position: absolute;\n    top: 0;\n    right: 24px;\n    width: calc(100% - 320px);\n    height: 96px;\n    border-bottom: 1px solid #D9D9D9;\n}\n\n.layout-component .pages-wrap {\n    position: absolute;\n    top: 120px;\n    right: 24px;\n    width: calc(100% - 320px);\n    height: calc(100vh - 144px);\n    overflow-y: auto;\n    padding: 0 20px;\n    padding-top: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import logoPng from '../../assets/images/logo.png'
import logoutPng from '../../assets/icons/logout2.png'
import './sidebar.css'
import { Link } from 'react-router-dom'
import { sidebarLinks } from './links'
import { useDispatch, useSelector } from 'react-redux'
import { changeSidebar } from '../../reducers/sidebarSlice'
import { userLogoutSuccess } from '../../reducers/authSlice'

const Sidebar = () => {

    const dispatch = useDispatch()
    const { sidebar } = useSelector(state => state.sidebar)

    const changeLink = (name) => {
        dispatch(changeSidebar(name))
    }

    const logout = () => {
        dispatch(userLogoutSuccess())
    }

    return (
        <div className='sidebar-component'>
            <div className='top'>
                <Link to='/' onClick={() => changeLink("postupleniye")}>
                    <img src={logoPng} alt='' />
                    <h1 className='karantin-logo'>Karantin.uz</h1>
                </Link>
            </div>


            <div className='links-wrap'>
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    {
                        sidebarLinks.map((item, index) => {
                            return (
                                <div className="accordion-item" key={index}>
                                    <h2 className="accordion-header" id={`flush-heading${item.id}`}>
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${item.id}`} aria-expanded="false" aria-controls={`flush-collapse${item.id}`}>
                                            {item.header}
                                        </button>
                                    </h2>
                                    <div id={`flush-collapse${item.id}`} className="accordion-collapse collapse" aria-labelledby={`flush-heading${item.id}`} data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            {
                                                item.body.map((item2, index2) => {
                                                    return (
                                                        <Link to={`/${item2.link}`} key={index2} onClick={() => changeLink(item2.link)}>
                                                            <p className={sidebar === item2.link ? 'active-link' : ''}>{item2.text}</p>
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className='logout' onClick={logout}>
                <img src={logoutPng} alt='' />
                <p>Выйти</p>
            </div>
        </div>
    )
}

export default Sidebar
import axios from "axios";
import { BASE_URL } from "../constants/constants";

const token = document.cookie ? document?.cookie?.split('=')[1] : null

const http = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'X-API-Key': "cJGZ8L1sDcPezjOy1zacPJZxzZxrPObm2Ggs1U0V+fE=INSECURE"
    }
})

http.interceptors.request.use(
    config => {
        if (token) {
            config.headers = {
                'Authorization': `Bearer ${token}`,
                'X-API-Key': "cJGZ8L1sDcPezjOy1zacPJZxzZxrPObm2Ggs1U0V+fE=INSECURE"
            }
            return config
        } else {
            return config
        }
    }
)

http.interceptors.response.use(
    response => response,
    error => {
        return Promise.reject(error)
        // if (error.response.status === 401) {
        //     window.localStorage.removeItem('token')
        //     window.location.reload()
        // } else {
        //     return Promise.reject(error)
        // }
    }
)

export default http
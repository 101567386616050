import { createSlice } from '@reduxjs/toolkit'
import { EXPIRES_DATE } from '../constants/constants'

const token = document.cookie ? document?.cookie?.split('=')[1] : null

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isLoading: false,
        loggedIn: token ? true : false,
        error: null,
    },
    reducers: {
        userLoginStart: (state) => {
            state.isLoading = true
        },
        userLoginSuccess: (state, action) => {
            state.isLoading = false
            state.loggedIn = true

            document.cookie = `token=${action.payload.token};expires=${EXPIRES_DATE}`

            // document.cookie = `refresh_token=${data.refresh}`
            // window.localStorage.setItem('token', action.payload.token)
            window.location.reload()
        },
        userLoginFailure: (state, error) => {
            state.isLoading = false
            state.error = error
        },
        userLogoutSuccess: (state) => {
            state.loggedIn = false

            document.cookie = `token=a;expires=Thu, 01 Jan 1970 00:00:00 GMT`
            // window.localStorage.removeItem('token')
            window.location.reload()
        }
    }
})

export const {
    userLoginStart,
    userLoginSuccess,
    userLoginFailure,
    userLogoutSuccess
} = authSlice.actions

export default authSlice.reducer
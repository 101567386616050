import React from 'react'
import { Modal } from 'antd';
import './respondApplication.css'

const RespondApplication = (props) => {

    const { isModalOpen, setIsModalOpen, respondApplicationById, setRespondText, respondText } = props

    return (
        <Modal
            title="Application"
            open={isModalOpen}
            onOk={() => { setIsModalOpen(false) }}
            onCancel={() => { setIsModalOpen(false) }}
            footer={null}
        >
            <form className='respond-form' onSubmit={respondApplicationById}>
                <div>
                    <textarea placeholder='Javob berish' value={respondText} onChange={e => setRespondText(e.target.value)}></textarea>
                </div>
                <button type='submit'>Yuborish</button>
            </form>
        </Modal>
    )
}

export default RespondApplication
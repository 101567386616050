import React from 'react'
import './App.css'
import Login from './views/login/Login'
import Layout from './components/layout/Layout'
import { useSelector } from 'react-redux'

const App = () => {

  const { loggedIn: auth } = useSelector(state => state.auth)

  return (
    <div className='App'>
      {
        auth ? <Layout /> : <Login />
      }
    </div>
  )
}

export default App
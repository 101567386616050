import http from "../axiosInterceptors"

const ApplicationService = {
    async GetAllApplications(offset, limit) {
        const response = await http.get(`/api/v1/applications?offset=${offset}&limit=${limit}`)
        return response.data
    },
    async GetApplicationDetails(uuid) {
        const response = await http.post(`/api/v1/application`, { uuid })
        return response.data
    },
    async RespondApplication(data) {
        const response = await http.post(`/api/v1/answer`, data)
        return response.data
    },
    async UpdateApplicationAnswer(data) {
        const response = await http.put(`/api/v1/answer`, data)
        return response.data
    }
}

export default ApplicationService
import React from 'react'
import './layout.css'
import Sidebar from '../sidebar/Sidebar'
import Navbar from '../navbar/Navbar'
import { useRoutes } from 'react-router-dom'
import { routes } from '../../router'

const Layout = () => {

    const routing = useRoutes(routes)

    return (
        <div className='layout-component'>
            <div className='sidebar-wrap'>
                <Sidebar />
            </div>
            <div className='navbar-wrap'>
                <Navbar />
            </div>
            <div className='pages-wrap'>
                {routing}
            </div>
        </div>
    )
}

export default Layout


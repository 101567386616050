// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.respond-form {
    width: 100%;
    text-align: right;
}

.respond-form textarea {
    width: 100%;
    height: 120px;
    border-radius: 4px;
    padding: 10px;
    text-indent: 20px;
}

.respond-form button {
    width: 120px;
    height: 36px;
    border-radius: 4px;
    border: none;
    background-color: #036323;
    color: #ffffff;
    margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/modals/respondApplication.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".respond-form {\n    width: 100%;\n    text-align: right;\n}\n\n.respond-form textarea {\n    width: 100%;\n    height: 120px;\n    border-radius: 4px;\n    padding: 10px;\n    text-indent: 20px;\n}\n\n.respond-form button {\n    width: 120px;\n    height: 36px;\n    border-radius: 4px;\n    border: none;\n    background-color: #036323;\n    color: #ffffff;\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react'
import './login.css'
import logoPng from '../../assets/images/logo.png'
import { useDispatch, useSelector } from 'react-redux'
import { userLoginFailure, userLoginStart, userLoginSuccess } from '../../reducers/authSlice'
import AuthService from '../../services/auth/auth.service'
import { toast } from 'react-toastify'

const Login = () => {

    const dispatch = useDispatch()
    const { isLoading } = useSelector(state => state.auth)

    const [eyeOpen, setEyeOpen] = useState(false)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')


    const loginHandler = async (e) => {
        e.preventDefault()

        const userData = {
            username,
            password
        }

        dispatch(userLoginStart())
        try {
            const response = await AuthService.UserLogin(userData)
            dispatch(userLoginSuccess(response.data))
            toast.success(response.statusText, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        } catch (err) {
            dispatch(userLoginFailure(err))
            toast.error(err.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
        }

    }

    return (
        <div className='login-page'>
            <div className='left'>
                <h1>Xush kelibsiz! <br />Online murojaatlar uchun</h1>
                <form onSubmit={loginHandler}>
                    <div className='input-wrap'>
                        <div className='bg'></div>
                        <input
                            type='text'
                            placeholder='Username'
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className='input-wrap'>
                        <div className='bg bg2'></div>
                        <input
                            type={eyeOpen ? 'text' : 'password'}
                            placeholder='Пароль'
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            required
                        />
                        <div className={eyeOpen ? 'eye-open' : 'eye-slash'} onClick={() => setEyeOpen(!eyeOpen)}></div>
                    </div>
                    <button type='submit' disabled={isLoading}>
                        <div style={{ display: isLoading ? 'block' : 'none' }} className='spinner-border mx-auto'></div>
                        <span style={{ display: !isLoading ? 'block' : 'none' }}>Kirish</span>
                    </button>
                </form>
            </div>
            <div className='right'>
                <img src={logoPng} alt='' />
                <h1 className='karantin'>KARANTIN.UZ</h1>
            </div>
        </div >
    )
}

export default Login
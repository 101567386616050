import React, { useEffect, useState } from 'react'
import './allAplications.css'
import ApplicationService from '../../services/applications/applications.service'
import { Pagination } from 'antd';
import Application from '../application/Application'

const AllAplications = () => {

    const [allAplications, setAllAplications] = useState([])
    const [size, setSize] = useState(10)
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(10)

    const getAllApplications = async () => {
        try {
            const response = await ApplicationService.GetAllApplications(offset, limit)
            setAllAplications(response.data)
            setSize(response.length)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getAllApplications()
        // eslint-disable-next-line
    }, [offset, limit])

    return (
        <div className='all-applications-page'>
            <table>
                <thead>
                    <tr>
                        <th>№</th>
                        <th>F.I.Sh</th>
                        <th>Telefon raqam</th>
                        <th>Email</th>
                        <th>Murojaatnoma mazmuni</th>
                        <th>Vaqt</th>
                        <th>Holati</th>
                        <th>Kodi</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        allAplications?.map((item, index) => {
                            return (
                                <Application
                                    key={index}
                                    application={item}
                                    getAllApplications={getAllApplications}
                                />
                            )
                        })
                    }
                </tbody>
            </table>
            <Pagination
                defaultCurrent={1}
                total={size}
                pageSize={limit}
                className='my-3'
                onChange={(page, l) => {
                    setLimit(l)
                    setOffset((page - 1) * limit)
                }}
                showSizeChanger={true}
                pageSizeOptions={[
                    5, 10, 20, 50, 100
                ]}
            />
        </div>
    )
}

export default AllAplications
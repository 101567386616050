export const sidebarLinks = [
    {
        id: 1,
        header: "Murojaatlar",
        body: [
            {
                text: "Barcha murojaatlar",
                link: "all-applications",
            },
        ]
    }
]
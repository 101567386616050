import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../reducers/authSlice";
import sidebarSlice from "../reducers/sidebarSlice";

export default configureStore({
    reducer: {
        auth: authSlice,
        sidebar: sidebarSlice
    },
    devTools: process.env.NODE_ENV !== 'production',
})
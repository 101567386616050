import React, { useEffect, useState } from 'react'
import notificationPng from '../../assets/icons/notification.png'
import profilePng from '../../assets/icons/profile-circle.png'
import './navbar.css'
import http from '../../services/axiosInterceptors'

const Navbar = () => {

    const [numbers, setNumbers] = useState(0)
    const getAllApplications = async () => {
        let notifications = 0
        try {
            const response = await http.get(`/api/v1/applications?limit=10000000`)

            for (let i = 0; i < response?.data?.data?.length; i++) {
                if (response?.data?.data[i].status === 'pending') {
                    notifications = notifications + 1
                }
            }
            setNumbers(notifications)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getAllApplications()
    }, [])

    return (
        <div className='navbar-component'>
            <div></div>
            <div className='d-flex'>
                <div className='notify-wrap'>
                    <img src={notificationPng} alt='' className='mx-4' />
                    <span>{numbers}</span>
                </div>
                <div className='profile'>
                    <img src={profilePng} alt='' className='mx-2' />
                    <h1>Karantin.uz</h1>
                </div>
            </div>
        </div>
    )
}

export default Navbar
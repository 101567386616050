// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Montserrat-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/icons/calendar.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: Montserrat;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

* {
    font-family: Montserrat;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-repeat: no-repeat;
    background-size: cover;
    width: 20px;
    height: 20px;
}

.App {
    width: 100%;
    min-height: 100vh;
    background-color: #F2F2F2;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,4CAA2D;AAC/D;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,yDAAoD;IACpD,4BAA4B;IAC5B,sBAAsB;IACtB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,yBAAyB;AAC7B","sourcesContent":["@font-face {\n    font-family: Montserrat;\n    src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf');\n}\n\n* {\n    font-family: Montserrat;\n}\n\ninput[type=\"date\"]::-webkit-calendar-picker-indicator {\n    background-image: url('./assets/icons/calendar.svg');\n    background-repeat: no-repeat;\n    background-size: cover;\n    width: 20px;\n    height: 20px;\n}\n\n.App {\n    width: 100%;\n    min-height: 100vh;\n    background-color: #F2F2F2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.all-applications-page {
    width: 100%;
    overflow-y: auto;
    text-align: center;
}

.all-applications-page table {
    width: 100%;
    text-align: center;
}

.all-applications-page table tr {
    border-bottom: 1px solid #00000020;
    transition: 0.2s;
}

.all-applications-page table td, th {
    padding: 10px;
    font-size: 16px;
    text-align: center;
}

.all-applications-page table tbody tr:hover {
    background-color: #00000010;
}

.all-applications-page .descr {
    display: block;
    width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/views/all-aplications/allAplications.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,kCAAkC;IAClC,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,cAAc;IACd,YAAY;AAChB","sourcesContent":[".all-applications-page {\n    width: 100%;\n    overflow-y: auto;\n    text-align: center;\n}\n\n.all-applications-page table {\n    width: 100%;\n    text-align: center;\n}\n\n.all-applications-page table tr {\n    border-bottom: 1px solid #00000020;\n    transition: 0.2s;\n}\n\n.all-applications-page table td, th {\n    padding: 10px;\n    font-size: 16px;\n    text-align: center;\n}\n\n.all-applications-page table tbody tr:hover {\n    background-color: #00000010;\n}\n\n.all-applications-page .descr {\n    display: block;\n    width: 300px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

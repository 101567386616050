// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-component {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-component .profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-component img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.navbar-component .profile h1 {
    color: #121212;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.navbar-component .notify-wrap {
    position: relative;
}

.navbar-component .notify-wrap span {
    padding: 0 5px;
    height: 18px;
    position: absolute;
    top: 16px;
    right: 10px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: #fff;
    font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/navbar/navbar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,qBAAqB;IACrB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,WAAW;IACX,eAAe;AACnB","sourcesContent":[".navbar-component {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.navbar-component .profile {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.navbar-component img {\n    width: 24px;\n    height: 24px;\n    cursor: pointer;\n}\n\n.navbar-component .profile h1 {\n    color: #121212;\n    font-family: Montserrat;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;\n    margin: 0;\n}\n\n.navbar-component .notify-wrap {\n    position: relative;\n}\n\n.navbar-component .notify-wrap span {\n    padding: 0 5px;\n    height: 18px;\n    position: absolute;\n    top: 16px;\n    right: 10px;\n    background-color: red;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 6px;\n    color: #fff;\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
